var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading ? _c("loading") : _vm._e(),
      _vm._v(" "),
      _c("header-navbar"),
      _vm._v(" "),
      _c("div", { staticClass: "slider-container" }, [
        _c("div", { staticClass: "container js-scroll-effect scroll-effect" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-10 col-sm-offset-1 slider" }, [
              _c(
                "div",
                { staticClass: "flexslider" },
                [
                  _vm.slides
                    ? _c(
                        "swiper",
                        {
                          ref: "topSwiper",
                          attrs: { options: _vm.swiperOptions },
                        },
                        [
                          _vm._l(_vm.slides, function (slide) {
                            return _c("swiper-slide", { key: slide.postId }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.postDetailUrl(slide.slug),
                                  },
                                },
                                [
                                  _vm.checkObjHasProp(slide, "images") &&
                                  slide.images.length > 0
                                    ? _c("fb-img", {
                                        attrs: {
                                          fileId: slide.images[0].fileId,
                                          mimeType: slide.images[0].mimeType,
                                          size: "953x458xc",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex-caption" }, [
                                _vm._v(_vm._s(slide.title)),
                              ]),
                            ])
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "swiper-button-prev swiper-button-item",
                            attrs: { slot: "button-prev" },
                            slot: "button-prev",
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "swiper-button-next swiper-button-item",
                            attrs: { slot: "button-next" },
                            slot: "button-next",
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "swiper-pagination",
                    attrs: { slot: "pagination" },
                    slot: "pagination",
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "new-container" }, [
        _c(
          "div",
          {
            staticClass: "container js-scroll-effect scroll-effect",
            attrs: { "data-start-pos": "200" },
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm.posts
              ? _c(
                  "div",
                  { staticClass: "row", attrs: { id: "articles" } },
                  _vm._l(_vm.posts, function (post) {
                    return _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "a",
                        { attrs: { href: _vm.postDetailUrl(post.slug) } },
                        [
                          _c(
                            "div",
                            { staticClass: "new" },
                            [
                              post.images.length > 0
                                ? _c("fb-img", {
                                    attrs: {
                                      fileId: post.images[0].fileId,
                                      mimeType: post.images[0].mimeType,
                                      size: "725x348xc",
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: "/assets/img/noimg.png",
                                      alt: "No Image",
                                    },
                                  }),
                              _vm._v(" "),
                              _c("h3", [_vm._v(_vm._s(post.title))]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm._f("substr")(post.bodyText, 72))
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "js-scroll-effect scroll-effect",
        attrs: { "data-start-pos": "200" },
        domProps: { innerHTML: _vm._s(_vm.contentTopMiddle) },
      }),
      _vm._v(" "),
      _vm.pickups.length > 0
        ? _c("div", { staticClass: "pickup-container" }, [
            _c(
              "div",
              {
                staticClass: "container js-scroll-effect scroll-effect",
                attrs: { "data-start-pos": "200", id: "pickup-box" },
              },
              [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row", attrs: { id: "pickup" } },
                  _vm._l(_vm.pickups, function (pickup) {
                    return _c(
                      "div",
                      { staticClass: "col-sm-10 col-sm-offset-1 pickup-list" },
                      [
                        _c("div", { attrs: { role: "tabpanel" } }, [
                          _c("div", { staticClass: "tab-content" }, [
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade in active",
                                attrs: { role: "tabpanel", id: "tab1" },
                              },
                              [
                                pickup.images
                                  ? _c(
                                      "div",
                                      { staticClass: "pickup-image col-sm-3" },
                                      [
                                        _c("fb-img", {
                                          attrs: {
                                            fileId: pickup.images[0].fileId,
                                            mimeType: pickup.images[0].mimeType,
                                            size: "400x400xc",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "pickup-text col-sm-9" },
                                  [
                                    _c("h3", [_vm._v(_vm._s(pickup.title))]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("substr")(pickup.bodyText, 125)
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: `/posts/?id=${pickup.slug}`,
                                        },
                                      },
                                      [_vm._v("もっと見る")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "pickup-container js-scroll-effect scroll-effect",
          attrs: { "data-start-pos": "200" },
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("h3", [_vm._v("パルシステムの重要なお知らせ")]),
            _vm._v(" "),
            _c("div", { staticClass: "row", attrs: { id: "itemInfoBox" } }, [
              _c("div", { staticClass: "col-sm-10 col-sm-offset-1" }, [
                _vm.palInfos
                  ? _c(
                      "ul",
                      { attrs: { id: "itemInfoList" } },
                      _vm._l(_vm.palInfos, function (item) {
                        return _c("li", { key: item.id }, [
                          _c("ul", [
                            _c("li", [
                              _vm._v(_vm._s(_vm._f("dateFormat")(item.date))),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: { href: item.link, target: "_blank" },
                                },
                                [_vm._v(_vm._s(item.title.rendered))]
                              ),
                            ]),
                          ]),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { domProps: { innerHTML: _vm._s(_vm.contentTopBottom) } }),
      _vm._v(" "),
      _c("common-footer", { attrs: { "is-top-page": true } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 new-title" }, [
        _c("h2", [_vm._v("最新情報")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 new-title" }, [
        _c("h2", [_vm._v("ピックアップ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }