<template>
<div>
  <loading v-if="isLoading"></loading>

  <header-navbar></header-navbar>

  <div class="slider-container">
    <div class="container js-scroll-effect scroll-effect">
      <div class="row">
        <div class="col-sm-10 col-sm-offset-1 slider">
          <div class="flexslider">
            <swiper
              v-if="slides"
              ref="topSwiper"
              :options="swiperOptions"
            >
              <swiper-slide
                v-for="slide in slides"
                :key="slide.postId"
              >
                <a :href="postDetailUrl(slide.slug)">
                  <fb-img
                    v-if="checkObjHasProp(slide, 'images') && slide.images.length > 0"
                    :fileId="slide.images[0].fileId"
                    :mimeType="slide.images[0].mimeType"
                    size="953x458xc"
                  ></fb-img>
                </a>
                <div class="flex-caption">{{ slide.title }}</div>
              </swiper-slide>
              <div class="swiper-button-prev swiper-button-item" slot="button-prev"></div>
              <div class="swiper-button-next swiper-button-item" slot="button-next"></div>
            </swiper>
            <div class="swiper-pagination" slot="pagination"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="new-container">
    <div class="container js-scroll-effect scroll-effect" data-start-pos="200">
      <div class="row">
        <div class="col-sm-12 new-title">
          <h2>最新情報</h2>
        </div>
      </div>
      <div
        v-if="posts"
        class="row"
        id="articles"
      >
        <div
          v-for="post in posts"
          class="col-sm-3"
        >
          <a :href="postDetailUrl(post.slug)">
            <div class="new">
              <fb-img
                v-if="post.images.length > 0"
                :fileId="post.images[0].fileId"
                :mimeType="post.images[0].mimeType"
                size="725x348xc"
              ></fb-img>
              <img
                v-else
                src="/assets/img/noimg.png"
                alt="No Image"
              >
              <h3>{{ post.title }}</h3>
              <div>{{ post.bodyText | substr(72) }}</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div
    v-html="contentTopMiddle"
    class="js-scroll-effect scroll-effect"
    data-start-pos="200"
  ></div>

  <div
    v-if="pickups.length > 0"
    class="pickup-container"
  >
    <div class="container js-scroll-effect scroll-effect" data-start-pos="200" id="pickup-box">
      <div class="row">
          <div class="col-sm-12 new-title">
            <h2>ピックアップ</h2>
          </div>
        </div>
        <div class="row" id="pickup">

          <div
            v-for="pickup in pickups"
            class="col-sm-10 col-sm-offset-1 pickup-list"
          >
            <div role="tabpanel">
              <!-- Tab panes -->
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane fade in active" id="tab1" >
                  <div
                    v-if="pickup.images"
                    class="pickup-image col-sm-3"
                  >
                    <fb-img
                      :fileId="pickup.images[0].fileId"
                      :mimeType="pickup.images[0].mimeType"
                      size="400x400xc"
                    ></fb-img>
                  </div>
                  <div class="pickup-text col-sm-9">
                    <h3>{{ pickup.title }}</h3>
                    <div>{{ pickup.bodyText | substr(125) }}</div>
                    <a :href="`/posts/?id=${pickup.slug}`">もっと見る</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
    </div>
  </div>

  <div class="pickup-container js-scroll-effect scroll-effect" data-start-pos="200">
    <div class="container">
      <h3>パルシステムの重要なお知らせ</h3>
      <div class="row" id="itemInfoBox">
        <div class="col-sm-10 col-sm-offset-1">
          <ul
            v-if="palInfos"
            id="itemInfoList"
          >
            <li
              v-for="item in palInfos"
              :key="item.id"
            >
              <ul>
                <li>{{ item.date | dateFormat }}</li>
                <li><a :href="item.link" target="_blank">{{ item.title.rendered }}</a></li>
              </ul>
            </li>
          </ul>
        </div>	
      </div>
    </div>
  </div>

  <div
    v-html="contentTopBottom"
  ></div>

  <common-footer
    :is-top-page="true"
  ></common-footer>
</div>
</template>
<script>
import EasyScrollEffect from 'easy-scroll-effect'
import config from '@/config/config'
import media from '@/util/media'
import str from '@/util/str'
import { Post, PalInfo } from '@/api'
import HeaderNavbar from '@/components/organisms/HeaderNavbar'
import CommonFooter from '@/components/organisms/CommonFooter'
import FbImg from '@/components/atoms/FbImg'
import Loading from '@/components/molecules/Loading'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'Top',

  components: {
    HeaderNavbar,
    CommonFooter,
    FbImg,
    Loading,
    Swiper,
    SwiperSlide,
  },

  directives: {
    swiper: directive
  },

  data(){
    return {
      pageName: 'top',
      slides: [],
      posts: [],
      pickups: [],
      palInfos: [],
      contentTopMiddle: '',
      contentTopBottom: '',
      swiperOptions: {
        loop: true,
        speed: 600,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      },
      isLoadingSlide: false,
      isLoadingPosts: false,
    }
  },

  computed: {
    swiper() {
      return this.$refs.topSwiper.$swiper
    },

    isLoadingPage() {
      return this.isLoadingSlide || this.isLoadingPosts
    },
  },

  watch: {
    isLoadingPage(val) {
      if (val === true) {
        this.$store.dispatch('setLoading', true)
      } else {
        this.$store.dispatch('setLoading', false)
      }
    },
  },

  mounted() {
    EasyScrollEffect.init()
  },

  destroyed() {
    EasyScrollEffect.destroy()
  },

  async created() {
    await this.setSlides()
    await this.setPosts()
    await this.setContent('top-middle')
    await this.setPickups()
    await this.setPalInfos()
    await this.setContent('top-bottom')
    EasyScrollEffect.init()
  },

  methods: {
    async setPosts() {
      this.isLoadingPosts = true
      //this.$store.dispatch('setLoading', true)
      const params = { count: this.config.posts.count }
      try {
        const res = await Post.getPosts(this.serviceId, null, params)
        this.posts = res.items
        this.isLoadingPosts = false
        //this.$store.dispatch('setLoading', false)
      } catch (err) {
        this.isLoadingPosts = false
        console.log(err);//!!!!!!
        //this.$store.dispatch('setLoading', false)
      }
    },

    async setContent(slug) {
      const serviceId = config.content.serviceId
      //this.$store.dispatch('setLoading', true)
      try {
        const prop = str.convKebabToCamel(`content-${slug}`)
        const res = await Post.getPosts(serviceId, slug)
        this[prop] = res.bodyHtml
        //this.$store.dispatch('setLoading', false)
      } catch (err) {
        console.log(err);//!!!!!!
        //this.$store.dispatch('setLoading', false)
      }
    },

    async setSlides() {
      this.isLoadingSlide = true
      //this.$store.dispatch('setLoading', true)
      try {
        const params = {
          count: this.config.slide.count,
          order: 'desc',
        }
        const res = await Post.getGroups(this.serviceId, this.config.slide.slug, params)
        this.slides = res.posts
        this.isLoadingSlide = false
        //this.$store.dispatch('setLoading', false)
      } catch (err) {
        this.isLoadingSlide = false
        console.log(err);//!!!!!!
        //this.$store.dispatch('setLoading', false)
      }
    },

    async setPickups() {
      //this.$store.dispatch('setLoading', true)
      try {
        const params = {
          count: this.config.pickups.count,
          order: 'desc',
        }
        const res = await Post.getGroups(this.serviceId, this.config.pickups.slug, params)
        if (this.checkObjHasProp(res, 'posts')) {
          this.pickups = res.posts
        }
        //this.$store.dispatch('setLoading', false)
      } catch (err) {
        console.log(err);//!!!!!!
        //this.$store.dispatch('setLoading', false)
      }
    },

    async setPalInfos() {
      //this.$store.dispatch('setLoading', true)
      try {
        this.palInfos = await PalInfo.getList(config.palInfoApi.path, config.palInfoApi.params)
        //this.$store.dispatch('setLoading', false)
      } catch (err) {
        console.log(err);//!!!!!!
        //this.$store.dispatch('setLoading', false)
      }
    },
  },
}
</script>
<style>
.swiper-pagination {
  bottom: -45px;
  width: 100%;
}
.swiper-pagination-bullet {
  margin: 6px;
  background-color: rgba(0,0,0,0.9);
  height: 11px;
  width: 11px;
}

.flexslider {
  margin-top: 45px;
}
.swiper-button-prev::after, .swiper-button-next::after {
  font-family: "flexslider-icon";
  color: #fff;
  background: #c81414;
}
.swiper-button-prev::after, .swiper-container-rtl .swiper-button-next::after {
  content: '\f001';
}
.swiper-button-next::after, .swiper-container-rtl .swiper-button-prev::after {
  content: '\f002';
}
#articles {
  min-height: 300px;
}

@media (max-width: 860px) {
  .flexslider {
    min-height: 150px;
  }
  .swiper-button-prev, .swiper-button-next {
    top: 10%;
  }
  .swiper-button-prev::after, .swiper-button-next::after {
    font-size: 18px;
    height: 32px;
    padding: 8px 10px;
  }
}
@media (min-width: 861px) {
  .flexslider {
    min-height: 300px;
  }
  .swiper-button-prev::after, .swiper-button-next::after {
    font-size: 26px;
    line-height: 2.4;
    padding: 30px 20px;
  }
  .swiper-button-item {
    text-decoration:none;
    display: block;
    width: 60px;
    height: 60px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: rgba(0,0,0,0.8);
    text-shadow: 1px 1px 0 rgba(255,255,255,0.3);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
  }
  .flexslider:hover .swiper-button-prev { opacity: 0.7; left: 10px; }
  .flexslider:hover .swiper-button-next { opacity: 0.7; right: 10px; }
  .flexslider:hover .swiper-button-next:hover, .flexslider:hover .swiper-button-prev:hover { opacity: 1; }
}
</style>

